import { useGetUserInfoClientSide } from "@/utils/user/user.util"

const findKeys = (obj: any, productID: any) =>
  Object.keys(obj).filter(key => { return key === productID });

export const productArrayAccessCheck = (
    productsArray: any,
    productVersionsList: any,
    lang: string
  ) => {
    const { userInfo } = useGetUserInfoClientSide()
    const productsMetaDataArray = productVersionsList?.products

    try {
        // iterate through the provided products array to find latest eligible version for each product
        const checkedProductsArray: any = productsArray.map((product: any, index: number) => {

            let productID = product?.id
            // once an eligible version is found, set this variable to stop processing so we don't override latest link with earlier eligible links
            let accessibleVersion = null
            if(productsMetaDataArray && productsMetaDataArray[productID]?.displayVersionList[lang]?.length > 0) {

                //get displayList for current product from productsMetaData
                const versionList: any = productsMetaDataArray[productID]?.displayVersionList[lang]?.sort((a: any, b:any) => {
                    const key1: any = Object.keys(a)[0];
                    const key2: any = Object.keys(b)[0]
        
                    if(key2 > key1) return 1
                    if(key2 < key1) return -1
                    return 0
                });
                let displayList = versionList?.length > 0 && versionList[0]
            
                // sort display list so we always start with the latest version and work backwards to find the latest eligible link, if one exists
                let sortedDisplayList = displayList && Object.keys(displayList).sort().reverse()  

                for (let key in sortedDisplayList){
                    if(accessibleVersion == null){
                        //this is the version we are testing against the user's restricted-private access product info
                        let thisVersion = sortedDisplayList[key]
                        let accessLevel = displayList[thisVersion].visibilityLevel
                        if (accessLevel == 'restricted-private') {
                            // check here if user has any restricted private product access
                            let restrictedProducts = userInfo?.restrictedPrivateProducts
                            if (restrictedProducts) {
                                let versions = null
                                for (let key in restrictedProducts){
                                    if(accessibleVersion == null){
                                        let thisProduct = restrictedProducts[key]
                                        let matchedProductName = findKeys(thisProduct, productID)
                                        if (matchedProductName == productID) {
                                            versions = thisProduct[productID]?.us
                                            if(versions?.includes(thisVersion)){
                                                // check if the current product version is present
                                                // in users restricted-private products versions array
                                                accessibleVersion = thisVersion
                                            } 
                                        }
                                    }
                                }  
                            } else {
                                // if user has no restricted private product access, set accessible version to null
                                // so it can be removed from the renderable products array
                                accessibleVersion = null
                            }

                        } else {
                            // for access levels other than restricted-private, render the version sent from AEM
                            accessibleVersion = thisVersion  
                        }
                    }
                } 
            } else if(productsMetaDataArray && !productsMetaDataArray[productID]?.displayVersionList[lang]){
                console.log(`Product ${productID} does not have a display version List value returned from API`)
            }
            
            let checkedProduct = product
            //set product to null so it can be removed if there is no allowed version for this product
            if(accessibleVersion == 'none' || accessibleVersion == null) {
                checkedProduct = null
            } else {
                checkedProduct.versionNumber = accessibleVersion 
            }
            return checkedProduct
        })

        // filter out null values from products array
        const accessibleProductsArray = checkedProductsArray.filter(Boolean)
        accessibleProductsArray?.sort(function (a: any, b: any) {
            if (a.name < b.name) {
            return -1;
            }
            if (a.name > b.name) {
            return 1;
            }
            return 0;
        });

        return accessibleProductsArray
    } catch (error) {
        throw error
    }
  }